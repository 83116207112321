import { Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage.js";
import ContactPage from "./pages/ContactForm.js";
import Advanced from "./pages/Advanced.js";

export const routes = [
  {
    path: "/advanced",
    element: <Advanced />,
  },
  {
    path: "/test",
    element: <>eygv</>,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "",
    element: <Navigate to="/contact" />,
  },
];
