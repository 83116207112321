import React, { useState } from "react";

const ContactForm = () => {
  const [formFields, setFormFields] = useState([
    {
      name: "First Name",
      type: "text",
      require: true,
      value: "",
      placeholder: "Type here your First Name",
    },
    {
      name: "Last Name",
      type: "text",
      require: true,
      value: "",
      placeholder: "Type here your Last Name",
    },
    {
      name: "Email",
      type: "email",
      require: true,
      value: "",
      placeholder: "Type here your Email",
    },
    {
      name: "Street",
      type: "text",
      require: true,
      value: "",
      placeholder: "Type here your Street",
    },
    {
      name: "City",
      type: "text",
      require: true,
      value: "",
      placeholder: "Type here your City",
    },
    {
      name: "Postal Code",
      type: "text",
      require: true,
      value: "",
      placeholder: "Type here your Postal Code",
    },
  ]);

  const handleInputChange = (index, value) => {
    const updatedFormFields = [...formFields];
    updatedFormFields[index].value = value;
    setFormFields(updatedFormFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="grid h-screen place-items-center">
      <form
        className="flex flex-col bg-slate-300 dark:bg-slate-800 text-black dark:text-white p-16 rounded-xl max-w-4xl text-center m-24 shadow-xl"
        onSubmit={handleSubmit}
      >
        <h3 className="font-black text-2xl m-5">Personal Information</h3>
        {formFields.map((field, index) => (
          <div key={index}>
            <label htmlFor={field.name.toLowerCase().replace(" ", "-")} className="mt-2">
              {field.name}
            </label>
            <input
              type={field.type}
              id={field.name.toLowerCase().replace(" ", "-")}
              name={field.name.toLowerCase().replace(" ", "-")}
              value={field.value}
              onChange={(e) => handleInputChange(index, e.target.value)}
              placeholder={field.placeholder}
              className="w-full border rounded p-2 dark:bg-slate-600 dark:text-white"
            />
          </div>
        ))}
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-8 py-2 px-4 rounded"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
