import { useState } from "react";

const LoginFrom = () => {
    const [email,setEmail] = useState('pers@gabs.eu.org');
return (
<div class="login-container">
    <h1 className="font-bold underline text-3xl bg-red-500 ">User Login</h1>
    <form class="login-form">
        <input type="email" name="email" placeholder="Email" value="email"></input>
        <input type="password" name="password" placeholder="Password"></input>
        <button type="submit">Login</button>
    </form>
    <a href="#">Forgot Password?</a>
</div>
)
}

export default LoginFrom;