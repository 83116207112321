import React, { useState, useEffect } from "react";
// import { useSearchParams } from "react-router-dom";
import { Pagination } from 'flowbite-react';

const Advanced = () => {
//   let [searchParms, setSearchParams] = useSearchParams();
  
  const ITEMS_PER_PAGE = 5;

  const [storeItems, setStoreItems] = useState([
    {
      name: "Apple",
      category: "Food",
      price: "10",
      barcode: "47 9824372934",
    },
    {
      name: "Banana",
      category: "Food",
      price: "20",
      barcode: "47 9824372934",
    },
    {
      name: "Pizza",
      category: "Food",
      price: "120",
      barcode: "47 2463424239",
    },
    {
      name: "Paper",
      category: "Utils",
      price: "308538049",
      barcode: "72 8243742837",
    },
    {
      name: "Gum",
      category: "Food",
      price: "120",
      barcode: "23 2347432237",
    },
    {
      name: "Something 1",
      category: "The thing!",
      price: "0101",
      barcode: "10 1010101010",
    },
    {
      name: "Something 2",
      category: "The thing!1",
      price: "10101",
      barcode: "01 010101010",
    },
    {
      name: "Something 3",
      category: "The thing!2",
      price: "234",
      barcode: "27 3497329424",
    },
    {
      name: "Something 4",
      category: "The thing!3",
      price: "234",
      barcode: "27 3497329424",
    },
    {
      name: "Something 5",
      category: "The thing!4",
      price: "322",
      barcode: "23 239842437",
    },
  ]);
  const [storeItem, setStoreItem] = useState({
    name: "",
    category: "",
    price: 0,
    barcode: "",
  });
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

//   const pageParam = parseInt(searchParams.get("page")) || 1;

  const handleChange = (e) => {
    setStoreItem({ ...storeItem, [e.target.name]: e.target.value });
  };

  const handleAddItem = () => {
    setStoreItems([...storeItems, storeItem]);
    setStoreItem({ name: "", category: "", price: 0, barcode: "" });
  };

/*  useEffect(() => {
    const pageParam = searchParams.get("page");
    const parsedPage = parseInt(pageParam, 10);

    if (!isNaN(parsedPage) && parsedPage > 0) {
      setCurrentPage(parsedPage);
    }
  }, [searchParams]);
  
  */
 
  const onPageChange = (page) => setCurrentPage(page);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const filteredStoreItems = storeItems
    .filter((item) => item.name.toLowerCase().startsWith(searchTerm.toLowerCase()))
    .slice(startIndex, endIndex); 

  return (
    <div className="p-8 p">
      <div className="flex gap-2 items-center w rounded-lg">
        <div>
          <input
            type="text"
            name="name"
            placeholder="Enter the Name of the item"
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="text"
            name="category"
            placeholder="Enter Category"
            value={storeItem.category}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="number"
            name="price"
            placeholder="Enter Price"
            value={storeItem.price}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="text"
            name="barcode"
            placeholder="Enter Barcode number"
            value={storeItem.barcode}
            onChange={handleChange}
          />
        </div>
        <button
          className="px-2 py-1 m-2 bg-blue-100 dark:bg-slate-700 rounded-md"
          onClick={handleAddItem}
        >
          Add
        </button>
      </div>

      <div>
        <input
          type="text"
          placeholder="Search by Name"
          value={searchTerm}
          className=" absolute top-0 right-0 m-5"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <table
        className="min-w-full bg-slate-50 dark:bg-slate-700 dark:text-white p-10"
        border={0}
      >
        <thead>
          <tr className=" bg-slate-200 dark:bg-slate-700 dark:text-white h-14">
            <th className="py-2 px-4 font-extrabold">Name</th>
            <th className="py-2 px-4 font-extrabold">Category</th>
            <th className="py-2 px-4 font-extrabold">Price</th>
            <th className="py-2 px-4 font-extrabold">Barcode</th>
          </tr>
        </thead>
        <tbody>
          {filteredStoreItems.map((item, index) => (
            <tr key={index}>
              <td className="py-2 px-4">{item.name}</td>
              <td className="py-2 px-4">{item.category}</td>
              <td className="py-2 px-4">{item.price} MDL</td>
              <td className="py-2 px-4 barcode">{item.barcode}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(storeItems.length / ITEMS_PER_PAGE)}
          onPageChange={onPageChange}
          showIcons
        />
        </div>
  );
};

export default Advanced;