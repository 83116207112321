import { useRoutes } from 'react-router-dom';
import './App.css';
import { routes } from './route';

function App() {

  function AppRoutes() {
    const appRoutes = useRoutes(routes);
  
    return appRoutes;
  }

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;